(function ($) {
    $(document).ready(function () {

        var isMobile = $.checkMobileDevice();

        if (isMobile) {
            /* mobile only */
            $.initMobile();

            $('#header').addClass('nonFix');
            $('#footerToggle').remove();
            $.initMobileToolTip();
        } else {
            /* desktop only */
            $(document).load($(window).bind("resize", $.setBodyFontSize));
            $.setBodyFontSize();
            $.initMainMenu();
            !$("#content").hasClass('landingpage') ? $.initCategorySlider() : null;
            $.initDesktopToolTip();

            /* desktop header*/
            $('#header').removeClass('nonFix');

            $(document).on('scroll', function () {
                $('.ui-autocomplete').hide();

                if ($(document).scrollTop() > 150) {
                    $("#page").addClass('reduced-header');
                    if (!$('.mainNavigationLevel2').is(":visible")) {
                        $('#header').addClass('reduced');
                    }

                    $("#header").css('height', '5.3em');

                    // MET-1077
                    $(".greyWorm").css('top', '5.2em');

                    $(".logo img").css('height', '99.9%');
                    $("#page").css('padding', '5.3em 0 15em 0');
                } else {
                    $("#page").removeClass('reduced-header');
                    $('#header').removeClass('reduced');

                    $("#header").css('height', '6.55em');

                    // MET-1077
                    $(".greyWorm").css('top', '6.5em');

                    $(".logo img").css('height', '100%');
                    $("#page").css('padding', '6.55em 0 15em 0');

                }
            });

            /* desktop footer */
            if ($('#footer').length > 0) {
                if ($('body').height() <= $(window).innerHeight() + 50) {
                    $('#footer').addClass('always');
                } else {
                    $(window).scroll(function () {
                        if ($(window).scrollTop() > ($('body').height() - $(window).innerHeight() - 235)) {
                            $('#footer').removeClass('fix');
                        } else {
                            $('#footer').addClass('fix');
                        }
                        if ($(window).scrollTop() >= ($('body').height() - $(window).innerHeight())) {
                            $('#footer').removeClass('open');
                        }
                    });

                    $('#footerToggle').click(function (ev) {
                        $('#footer').toggleClass('open');
                    });
                }
            }
        }

        /* mobile & desktop*/

        /*search*/
        if ($('.searchItem').size() > 0) {
            var timer = null;

            $('.searchItem').click(function (ev) {
                ev.preventDefault();
                timer = setTimeout(function () {
                    $('.searchFormContainer').css('display', 'block');
                    $('form#searchForm :input').removeAttr('disabled');
                }, 500);
                $('#footer').css({'z-index': '0'});
            });

            $('.searchItem').mouseenter(function () {
                timer = setTimeout(function () {
                    $('.accessoryFlyout').css('display', 'block');
                    $('.accessoryImage').css('display', 'none');
                    $('.heading.accessory').css('visibility', 'hidden');
                    $('.accessoryFlyoutLeft:last-of-type').css('display', 'none');
                    $('input[value=accessory]').attr('disabled', true);
                }, 500);
                $('#footer').css({'z-index': '0'});
            });

            $('.accessoryFlyout').mouseleave(function () {
                clearInterval(timer);

                /*do not close flyout while suggest is open*/
                if ($('.ui-autocomplete').is(":visible")) {
                    return false;
                }

                $(this).stop(true, true).animate(
                    {'opacity': 1},
                    {
                        duration: 0,
                        complete: function () {
                            $(this).css('display', 'none');
                            $('.accessoryImage').css('display', 'block');
                            $('.heading.accessory').css('visibility', 'visible');
                            $('.accessoryFlyoutLeft:last-of-type').css('display', 'block');
                            $('input[value=accessory]').attr('disabled', false);
                        }
                    }
                );

                $('#footer').css({'z-index': '9500'});
            });
        }

        if ($('.closeSearch').size() > 0) {
            $('.closeSearch').click(function () {
                $('form#searchForm :input').attr('disabled', true);
                if (isMobile) {
                    $('.mobileSearchToggle').trigger('click');
                } else {
                    $('.searchItem').trigger('click');
                }
            });
        }
        /*search*/

        /* box hover */
        $('.contentToggle').mouseenter(function () {
            if ($(this).hasClass('boxHoverOut')) {
                return;
            }

            $($(this).parent()).animate(
                {'min-height': '100%'},
                {
                    duration: "slow",
                    complete: function () {
                        $(this).parents('div.box').addClass('open');
                        $(this).find('.contentToggle').css('transform', 'rotate(180deg)');
                        $(this).parents('div.box').find(".boxLongText").css('display', 'block');
                    }
                }
            );
            $(this).animate({'top': '0.5em'}, {duration: "slow"});
            $(this).addClass("boxHoverOut");
            $($(this).parent()).addClass("boxHover");
        });

        $('.box').mouseleave(function () {
            if ($(this).hasClass('open')) {
                $(this).removeClass('open');
                $(this).find(".boxLongText").css({'display': 'none'});
                $(this).find('.contentToggle').css('transform', 'rotate(0deg)');
                $(this).find(".boxHover").animate(
                    {'min-height': '0'},
                    {
                        duration: "slow",
                        complete: function () {
                            $(this).find(".boxContentContainer").removeClass("boxHover");
                            $(this).find(".contentToggle.boxHoverOut").removeClass("boxHoverOut");
                        }
                    });
                $(this).find(".contentToggle").animate({'top': '-1.5em'}, {duration: "slow"});
            }
        });

        $('.contentToggle').click(function () {
            if (!$(this).parent().parent().hasClass('open')) {
                $(this).mouseenter();
            } else {
                $('.box').mouseleave();
            }
        });
        /* box hover */

        /* video */
        $('.videoBox .boxShortText').click(function () {
            $(this).startVideo(1200);
        });

        $('.videoBox .boxImageContainer img').click(function () {
            $(this).startVideo(1200);
        });
        /* video */

        /* sidebar menu */
        if ($('#tree')) {
            $('#tree').find('li.firstLevel').find('.menuIcon').click(function () {
                var clickedLevel = $(this).attr('data-level');
                $(this).parents('li.' + clickedLevel).toggleClass('open');
                $(this).toggleClass('open');
            });
        }

        if ($('.treeLeft')) {
            $('.treeLeft').find('li.firstLevel').find('.menuIcon').click(function () {
                var clickedLevel = $(this).attr('data-level');
                $(this).parents('li.' + clickedLevel).toggleClass('open');
                $(this).toggleClass('open');
            });
        }

        if ($('div.csc-textpic').size() > 0) {
            $('div.csc-textpic').find('img').each(function (index, value) {
                var width = $(this).attr('width');
                if (width > 0) {
                    $(this).css('max-width', width + 'px');
                } else {
                    $(this).css('max-width', 'none');
                }
            });
        }
        /* sidebar menu */

        /*nfc store locator*/
        if ($('.storeLocatorTooltip').length > 0) {
            $("[id^=tt_]").each(function () {
                $(this).mouseover(function () {
                    var formRight = parseInt($('#storeLocatorSearchForm').position().left + $('#storeLocatorSearchForm').width() + 10);
                    eId = $(this).attr('id');
                    cId = $('#storeLocatorContainer').find('#' + eId.replace('tt_', 'tc_'));
                    $(cId).css('top', $(this).position().top);
                    $(cId).css('left', formRight);
                    $(cId).css('display', 'block');
                });

                $(this).mouseout(function () {
                    cId = $('#storeLocatorContainer').find('#' + eId.replace('tt_', 'tc_'));
                    $(cId).css('display', 'none');
                });
            });
        }
        /*nfc store locator*/

        /*product info link*/
        if ($('#prodinfolink').length > 0) {
            $("#prodinfolink").each(function () {
                $(this).initInfoLink();
            });
        }
        /*product info link*/

        /* product attribute pdfs toggle */
        $('.product-attributes-pdfs-links a.show').click(function () {
            $('.product-attributes-pdfs.toggle').show();
            $('.product-attributes-pdfs-links a').toggle();
        });
        $('.product-attributes-pdfs-links a.hide').click(function () {
            $('.product-attributes-pdfs.toggle').hide();
            $('.product-attributes-pdfs-links a').toggle();
        });
        /* product attribute pdfs toggle */

        /*center teaser box headline*/
        if ($('.greyTeaser').length > 0) {
            $('.greyTeaser').each(function () {
                $(this).centerHeading();
            });
        }
        /*center teaser box headline*/

        /*compare*/
        if ($('#compareListTable').length > 0) {
            $('#compareListTable').initCompareList();
        }
        /*compare*/

        /*filter toogle*/
        if ($('.filter').length > 0) {
            $(".filter").each(function () {
                var filter = $(this);
                var caption = $(this).find('.filterText').first();
                var icon = $(this).find('.iconElement').first();
                var toggleFilterBox = function () {
                    if (!filter.hasClass('open')) {
                        $(".filter").removeClass('open');
                        filter.addClass('open');
                    } else {
                        filter.removeClass('open');
                    }
                }
                caption.click(function (ev) {
                    toggleFilterBox();
                });
                icon.click(function (ev) {
                    toggleFilterBox();
                });
            });
        }
        /*filter toogle*/

        /*filter multiselect*/
        $('ul.fl_ul li button.submitButton').each(function () {
            $(this).click(function () {
                var $parentFilter = $(this).parents('div.filter.open');
                var $query = window.location.href;
                if ($query.indexOf("?") == -1) {
                    $joiner = '?';
                } else {
                    $joiner = '&';
                }
                var $attrName = $parentFilter.attr('title');
                $parentFilter.find('li.fl_li input[type="checkbox"]:checked').each(function () {
                    $attrAddition = 'attrib[' + $attrName + '][]=' + $(this).attr('name');
                    if (window.location.href.indexOf($attrAddition) == -1) {
                        $query += $joiner + $attrAddition;
                        $joiner = '&';
                    }
                });
                if ($query != window.location.href) {
                    window.location.href = $query;
                }
            });
        });

        /*filter multiselect*/


        function syncListHeight(parentElement) {
            var h = 15 * 16;
            $(parentElement).find('div.listDetails').each(function () {
                if ($(this).height() > h) {
                    h = $(this).height();
                }
            });
            $('div.listDetails').css('min-height', ((h / 16) + 4) + 'em');
        }

        //MET-653 Erweiterte Layouts und Backendmodul
        scaleImagesInit();
        adjustImageNoteTextHeight();

        neustaSlider.init();
        neustaCarousel.init();
        filmsModal.init();
        sameHeight.init();
        ceCounterCircle.init();
        ceCounterPie.init();
        ceCounterBar.init();
        ceContentSlider.init();
        bottomFlyout.init();
        dceYouTube.init();

        //MET-808 Landingpage - Zurück-nach-Oben-Button
        scrollToTop.init();

    });

    $(window).load(function () {
        lazyLoading();
    });

    if (window.location.hash.length > 0) {
        setTimeout(function () {
            window.scrollTo(0, $(window.location.hash).offset().top);
        }, 1500);
    }
})(jQuery);